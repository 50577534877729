import axios from '@/plugins/axios';

export function getDomain(domainName) {
    return axios({
        method: "GET",
        url: `domain/{domain}/`,
        data: {},
        urlParams: {
            domain: domainName
        }
    });
}

export function deactivateDomain(domainName, domainData = null) {
    const params = {
        method: "POST",
        url: `domain/{domain}/stop/`,
        urlParams: {
            domain: domainName
        }
    };
    if (domainData)
        params.data = domainData;

    return axios(params);
}

export function promoteShadowDomain(mainDomainName, shadowDomainData) {
    const params = {
        method: "POST",
        url: `domain/{domain}/promote_shadow/`,
        urlParams: {
            domain: mainDomainName
        },
        data: shadowDomainData
    };

    return axios(params);
}

export function deleteDomain(domainName, domainData = null) {
    const params = {
        method: "DELETE",
        url: `domain/{domain}/`,
        urlParams: {
            domain: domainName
        }
    };
    if (domainData)
        params.data = domainData;

    return axios(params);
}

export function deleteshadowDomain(domainName) {
    const params = {
        method: "POST",
        url: `domain/shadow_delete/`,
        data: {
            domains: domainName
        }
    };
    return axios(params);
}

export function resumeDomain(domainName, domainData) {
    const params = {
        method: "POST",
        url: `domain/{domain}/resume/`,
        urlParams: {
            domain: domainName
        }
    };
    if (domainData)
        params.data = domainData;

    return axios(params);
}

export function redeployDomain(domainName, domainData) {
    const params = {
        method: "POST",
        url: `domain/{domain}/redeploy/`,
        urlParams: {
            domain: domainName
        },
        timeout: 5000
    };
    if (domainData)
        params.data = domainData;

    return axios(params);
}

export function diagnoseDomain(domainName) {
    return axios.post(
        `domain/{domain}/check/`,
        {},
        {
            params: {},
            urlParams: {
                domain: domainName
            }
        }
    );
}

/* PLAN */

export function listPlan() {
  return axios.get('plan/');
}

export function payPlan(plan, coin, paymentType, additional_domain_number) {
  return axios.post(
    'plan/subscription/pay/',
    {
      plan: plan,
      currency2: coin,
      payment_type: paymentType,
      additional_domain_number: additional_domain_number
    },
  )
}

export function renewPlan(subscriptionID, coin, paymentType) {
  return axios.post(
    `plan/subscription/${subscriptionID}/renew/`,
    {
      currency2: coin,
      payment_type: paymentType
    },
  )
}

// export function payTrialPlan(subscriptionID, coin, paymentType) {
//   return axios.post(
//     `plan/subscription/trial/${subscriptionID}/pay/`,
//     {
//       currency2: coin,
//       payment_type: paymentType
//     },
//   )
// }

export function subscriptionPlan() {
    return axios
    .get(`plan/subscription/`, {
        params: {},
        urlParams: {}
    });
}
